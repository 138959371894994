import React from "react";
import { contentProjectsAmProudOf } from "./../constants";

const aStyle = {
    fontSize: "16px",
};

export default function Projects() {
    return (
        <section className="resume-section" id="projects">
            <div className="resume-section-content">
                <h2 className="mb-5">Projects</h2>

                <div className="col">
                    {contentProjectsAmProudOf.map((e, i) => {
                        return (
                            <div key={i} className="col mt-5 mb-5 align-items-center ">
                                <p className="subheading">{e.title}</p>
                                <p>{e.desc}</p>
                                <a style={aStyle} href={e.link}>
                                    Visit Project
                                </a>
                            </div>
                        );
                    })}
                </div>
            </div>
        </section>
    );
}
