import React from 'react'

function About() {
  return (
    <section className="resume-section" id="about">
      <div className="resume-section-content">
        <h1 className="mb-0">
          Soheyb Merah
        </h1>
        <p className="lead mt-5">
          I spend my spare time learning and developing mainly web servers with many frameworks to solve problems
          with adequate tools along with version control systems and CI/CD tools, am also able to build mobile and website applications,
          furthermore. <br />
        </p>
        <div className="social-icons d-flex justify-content-center">
          <a className="social-icon" href="https://www.linkedin.com/in/soheyb-merah-1b297a13b/" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin-in" /></a>
          <a className="social-icon" href="https://github.com/splimter/" target="_blank" rel="noopener noreferrer"><i className="fab fa-github" /></a>
          <a className="social-icon" href="https://www.facebook.com/splimterxtenno/" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f" /></a>
        </div>
        <div className="subheading text-center">
          <a href="mailto:merah.soheyb@gmail.com">merah.soheyb@gmail.com</a>
        </div>
      </div>
    </section>

  )
}

export default About
